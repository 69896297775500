export default function (code) {
  switch (code) {
    case "0001": return "Este cupom que foi utilizado não é válido."
    case "BANK": return "Algo deu errado na compra, é necessário o cliente entrar em contato com o banco do cartão."
    case "BLACKLIST_PURCHASE": return "Após inúmeras tentativas de pagamento o banco bloqueou o cartão do cliente."
    case "INVALID_CVV": return "Código de segurança do cartão inválido."
    case "INVALID_CLIENT_DATA": return "Dados do cliente constam incorretos."
    case "DUPLICATE_PURCHASE": return "Cliente já efetuou uma compra deste produto"
    case "PRODUCT_OUT_OF_STOCK": return "Produto sem estoque."
    case "CREDIT_CARD_OPERATOR": return "Algo deu errado na compra, é necessário o cliente entrar em contato com a operadora do cartão."
    case "INVALID_DATA": return "Os dados do cartão foram inseridos incorretamente"
    case "INVALID_CREDIT_CARD": return "Transação não permitida para o cartão."
    case "INSUFFICIENT_FUNDS": return "Não há limite suficiente no cartão."
    case "INVALID_PAYMENT_TYPE": return "Método utilizado para o pagamento inválido."
    case "INVALID_INSTALLMENTS": return "Número ou valor das parcelas não foi autorizado."
    case "CURRENCY_NOT_SUPPORTED": return "Moeda não suportada."
    case "SUSPECTED_FRAUD": return "Transação não permitida, Suspeita de Fraude."
    case "GENERIC": return "Ocorreu um erro durante o processamento da compra!"
    // assinatura
    case '0404': return 'Nenhuma assinatura foi efetuada com este documento.'
    case '1404': return 'Esse produto não é uma assinatura.'
    case '1405': return 'Não há nenhuma assinatura vinculada a este documento.'
    default: return `Ocorreu um erro durante o processamento da compra. - Código: ${code}`
  }
}