import Rest from '@/services/Rest';

/**
 * @typedef {OffersService}
 */
export default class OffersService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/offers/search'
}