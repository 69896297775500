<template>
  <b-row class="item-dados">
    <b-col>
      <h5 class="titulo-item" :class="{'heaven': isHeaven}">{{ $t('shared.text_2088') }}</h5>
      <p class="label-campo">
        <span class="title-item">{{ $t('shared.text_2089') }}</span>
        <span v-if="sale.fiscalCenter.status === 'ISSUED' || sale.fiscalCenter.status === 'ISSUED_EXTERNALLY'" class="nota-status emitida">
          <img src="@/assets/img/icons/mini-check.svg" />
          {{ $t('shared.text_2090') }}
        </span>
        <span v-if="sale.note_by === 'Produtor'" class="nota-status emitida">
          <!-- Issue Note -->
          <template v-if="isInWarranty && (!sale.fiscalCenter || (sale.fiscalCenter && (sale.fiscalCenter.status === 'NOT_ISSUED' || sale.fiscalCenter.status === 'ERROR')))">
            <a
              class="desctitle-item cursor-pointer"
              style="color: rgb(33, 51, 210);text-decoration: underline;"
              @click="openModalIntegrationNotes"
            >
              {{ $t('shared.text_2091_1') }}
            </a>
          </template>
          <!-- Note in Process -->
          <template v-if="sale.fiscalCenter && sale.fiscalCenter.status === 'PROCESSING'">
            <p class="desctitle-item" style="color: #FF9E44;">
              {{ $t('shared.text_2091_2') }}
            </p>
          </template>
          <!-- Note Not Issued -->
          <template v-if="!isInWarranty &&  (!sale.fiscalCenter || (sale.fiscalCenter && (sale.fiscalCenter.status === 'NOT_ISSUED' || sale.fiscalCenter.status === 'ERROR')))">
            <img src="@/assets/img/icons/mini-x.svg" />
            {{ $t('shared.text_2091') }}
          </template>
        </span>
      </p>

      <p v-if="sale.fiscalCenter.status === 'ISSUED' || sale.fiscalCenter.status === 'ISSUED_EXTERNALLY'" class="label-campo">
        <span class="title-item">{{ $t('shared.text_2092') }}</span>
        <span>{{ sale.note_by }}</span>
      </p>
    </b-col>

    <ListIntegration
      :integrations="integrations"
      :modalToReturn="''"
      :user="user"
      :dataConfigurationOfPaidAt="dataConfigurationOfPaidAt"
      @integrationSelected="integrationSelected"
    />

  </b-row>
</template>

<script>
import moment from 'moment'
import Vue from 'vue'
import ListIntegration from '@/components/Seller/NoteCenter/modals/ListIntegration'

import NoteCenter from '@/services/resources/NoteCenter'
import NoteCenterIntegration from '@/services/resources/NoteCenterIntegration'

const noteCenterIntegrationApi = NoteCenterIntegration.build()
const apiNoteCenter = NoteCenter.build()

export default {
  name: 'SaleDetailsFiscalCenter',
  components: { ListIntegration },
  props: {
    sale: {
      required: true,
      type: Object|null,
      default: null
    }
  },
  data() {
    return {
      integrations: [],
      dataConfigurationOfPaidAt: {},
      emitManual: false
    }
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    },
    isInWarranty() {
      const now = moment()
      const warranty_period_end = moment(this.sale.paid_at).add(this.sale.product.warranty, 'days')
      return moment(now).isBefore(warranty_period_end)
    },
    user() {
      return this.$store.state.user.user
    }
  },
  methods: {
    openModalIntegrationNotes() {
      this.configurationOfPaidAt()
      this.$bvModal.show("modal_integrations")
      this.getIntegrations()
      this.emitManual = true
    },
    configurationOfPaidAt() {
      apiNoteCenter
        .get("configuration-paid")
        .then(res => { this.dataConfigurationOfPaidAt = res })
        .catch(err => console.error(err))
    },
    getIntegrations() {
      this.emitManual = false
      noteCenterIntegrationApi
        .get("")
        .then(res => { this.integrations = res })
        .catch(err => console.error(err))
    },
    integrationSelected(integration) {
      if (this.emitManual) {
        Vue.swal({
          title: this.$t('views.seller.note_center.text_1241'),
          text: `Deseja realmente emitir essa nota pela ${integration.name}`,
          type: "danger",
          showCancelButton: true,
          confirmButtonText: `${this.$t('views.seller.note_center.text_1243')}`,
          cancelButtonText: this.$t('views.seller.note_center.text_1500'),
          customClass: "sweet-container",
          confirmButtonClass: "button button-black mt-3 mb-3",
          cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
          buttonsStyling: false,
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$emit('update-loading', true)

            apiNoteCenter
              .post("warranty-emit", {
                sale_id: this.sale.id,
                integration_id: integration.id
              })
              .then(() => {
                this.$emit('update-sale')
                this.$bvToast.toast(`Iniciamos a emissão da nota na sua integração com ${integration.name}`, {
                  variant: "info",
                  title: `${this.$t('views.seller.note_center.text_1502')}`,
                  autoHideDelay: 8000,
                  appendToast: true
                })
              })
              .catch(err => console.error(err))
              .finally(() => this.$emit('update-loading', false))
          } else {
            this.openModalIntegrationNotes()
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.item-dados {
  padding: 22px 0;
}
.item-dados + .item-dados {
  border-top: 0.5px solid #ededf0;
}
.titulo-item {
  font-size: 18px;
  font-weight: 600;
  color: $base-color;
  margin-bottom: 15px;
}
.titulo-item.heaven {
  font-size: 18px;
  font-weight: 600;
  color: $base-color-heaven;
  margin-bottom: 15px;
}
.label-campo {
  font-size: 14px;
  font-weight: 600;
  color: #333;
}
.label-campo span {
  font-weight: 600;
}
.label-campo + .label-campo {
  margin-top: 5px;
}
.title-item {
  font-size: 11px;
  color: #81858e;
  display: block;
  font-weight: normal !important;
  margin-bottom: 3px;
}
.nota-status {
  display: flex;
  align-items: center;
  gap: 5px;
}
.desctitle-item {
  font-size: 14px;
  color: #333;
  display: block;
  font-weight: normal !important;
  margin-bottom: 3px;
}
</style>
