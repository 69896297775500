<template>
  <div>
    <slot name="button" :openModal="openModal"> </slot>
    <b-modal
      :id="`ticket-reassignment-${id}`"
      size="md"
      :title="$t('ticket_sale_preview.text_2')"
    >
      <b-container v-if="!loading">
        <b-row>
          <b-col cols="6">
            <b>{{ title }}</b>
          </b-col>
          <b-col cols="6" class="text-right">
            <b-badge class="custom-badge">{{ subtitle }}</b-badge>
          </b-col>
          <b-col cols="12" class="mt-3">
            <p>
              <small>
                {{ $t("ticket_sale_preview.text_7") }}
              </small>
            </p>
          </b-col>

          <b-col cols="6" class="mt-3">
            <b-form-group :label="$t('ticket_sale_preview.text_6')" label-for="document">
              <b-form-input
                id="document"
                v-model="reassignmentForm.document"
                v-mask="['###.###.###-##']"
                type="text"
                @input="validateForm"
                @blur="validateDocument"
                :state="documentState"
                aria-describedby="document-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="document-feedback">
                {{ documentError }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <b-col cols="6" class="mt-3">
            <b-form-group :label="$t('ticket_sale_preview.text_3')" label-for="name">
              <b-form-input
                id="name"
                v-model="reassignmentForm.name"
                type="text"
                @input="validateForm"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-3">
            <b-form-group :label="$t('ticket_sale_preview.text_4')" label-for="email">
              <b-form-input
                id="email"
                v-model="reassignmentForm.email"
                type="text"
                @input="validateForm"
                @blur="checkEmailValidity"
                :state="emailState"
                aria-describedby="email-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="email-feedback">
                {{ emailError }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-3">
            <b-form-group :label="$t('ticket_sale_preview.text_5')" label-for="cellphone">
              <VuePhoneNumberInput
                    id="cellphone"
                    v-model="cellphone"
                    :translations="$i18n.locale === 'pt' ? translations_pt : (
                      $i18n.locale === 'es' ? translations_es : translations_en
                    )"
                    error-color="#ff0c37"
                    size="lg"
                    default-country-code="BR"
                    :preferred-countries="['BR', 'PT', 'US', 'FR', 'ES']"
                    ref="telinput"
                    :required="true"
                    :countries-height="50"
                    :border-radius="5"
                    @update="onUpdateCellPhone"
                  />
                  <b-form-invalid-feedback style="margin-top: 15px;" :state="isPhoneValidation">
                    {{ $t("ticket_sale_preview.text_8") }}
                  </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>

      <div
        v-if="loading"
        class="table-body py-4 d-flex justify-content-center align-items-center"
      >
        <b-spinner variant="dark" label="Spinning"></b-spinner>
      </div>

      <template v-slot:modal-footer="{ cancel }">
        <BaseButton
          variant="link-info"
          @click="cancel()"
          class="mr-4"
          :disabled="loading"
        >
          {{ $t("shared.text_1984") }}
        </BaseButton>

        <div id="btn-atribuir">
          <BaseButton
            variant="black"
            @click="onSubmit"
            :disabled="loading || !activeButton"
          >
            {{ $t("ticket_sale_preview.text_2") }}
          </BaseButton>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import validateEmail from "@/utils/validateEmail.js";
import validateCPF from "@/utils/validateCPF.js";
import TicketService from "@/services/resources/TicketService";
import VuePhoneNumberInput from 'vue-phone-number-input'
const service = TicketService.build();

export default {
  name:"TicketReassignment",
  components: {
    VuePhoneNumberInput,
  },
  props: ['sale_id'],
  data() {
    return {
      id: parseInt(Math.random() * 1000),
      loading: false,
      title: "",
      subtitle: "",
      ticket_id: 0,
      reassignmentForm: {
        name: "",
        document: "",
        cellphone: "",
        email: "",
      },
      results_tel: {
        isValid: true,
      },
      cellphone:"",
      activeButton: false,
      emailError: "",
      documentError: "",
      phoneError: "",
      emailState: null,
      documentState: null,
      translations_pt: {
        countrySelectorLabel: "Código do país",
        countrySelectorError: "Escolha um país",
        phoneNumberLabel: "Número de Telefone",
        example: "Exemplo: ",
      },
      translations_en: {
        countrySelectorLabel: "Country code",
        countrySelectorError: "Choose a country",
        phoneNumberLabel: "Phone number",
        example: "Example: ",
      },
      translations_es: {
        countrySelectorLabel: "Código de país",
        countrySelectorError: "Elige un país",
        phoneNumberLabel: "Número de teléfono",
        example: "Ejemplo: ",
      },
    };
  },
  methods: {
    getTicketData() {
      this.loading = true;
      service
        .read({ id: `/details/${this.sale_id}` })
        .then((res) => {
          this.reassignmentForm = {
            name: res?.ticket[0].name || "",
            document: res?.ticket[0].document || "",
            email: res?.ticket[0].email || "",
            cellphone: res?.ticket[0].cellphone || "",
          };
          this.cellphone = res?.ticket[0].cellphone || ""
          this.ticket_id = res.ticket[0].id
          this.title = res.offer.name;
          this.subtitle = res.offer.offer_groups.name;
          
        })
        .catch(() => {
          this.$bvToast.toast("Não foi possível buscar o lote do ingresso", {
            title: "Greenn",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openModal() {
      this.$bvModal.show(`ticket-reassignment-${this.id}`);
      this.validateForm();
      this.loading = false
      this.title = ""
      this.subtitle = ""
      this.ticket_id = 0
      this.reassignmentForm = {
        name: "",
        document:"",
        cellphone: "",
        email: "",
      },
      this.results_tel = {
        isValid: true,
      },
      this.cellphone = ""
      this.activeButton = false
      this.emailError = ""
      this.documentError = ""
      this.phoneError = ""
      this.emailState = null
      this.documentState =null
      this.getTicketData();
    },
    validateForm() {
      const isEmailValid = validateEmail(this.reassignmentForm.email);
      const isDocumentValid = validateCPF(this.reassignmentForm.document);
      const isPhoneValid = this.results_tel.isValid;
      this.activeButton =
        Object.values(this.reassignmentForm).every(
          (field) => typeof field === "string" && field.trim() !== ""
        ) &&
        isEmailValid &&
        isDocumentValid &&
        isPhoneValid;
    },
    checkEmailValidity() {
      if (!validateEmail(this.reassignmentForm.email)) {
        this.emailError = "Email inválido.";
        this.emailState = false;
      } else {
        this.emailError = "";
        this.emailState = true;
      }
    },
    validateDocument() {
      if (!validateCPF(this.reassignmentForm.document)) {
        this.documentError = "CPF inválido.";
        this.documentState = false;
      } else {
        this.documentError = "";
        this.documentState = true;
      }
    },
    onSubmit() {
      this.loading = true;
      service
        .update(this.reassignmentForm,
          `reassignment/${this.sale_id}/${this.ticket_id}`)
        .then(async () => {
          this.$bvToast.toast("O ingresso foi reatribuído", {
            title: "Greenn",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });

          this.$bvModal.hide(`ticket-reassignment-${this.id}`);
        })
        .catch(() => {
          this.$bvToast.toast("Não foi possível reatribuir o ingresso.", {
            title: "Greenn",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(()=>{
          this.loading = false;
        })
    },
    onUpdateCellPhone(payload) {
      this.validateForm();
      if (payload.phoneNumber) {
        this.results_tel.isValid = payload.isValid;
        this.cellphone = `+${payload.countryCallingCode +
          payload.phoneNumber
            .replace(" ", "")
            .replace("-", "")
            .replace("(", "")
            .replace(")", "")}`;
        this.reassignmentForm.cellphone = this.cellphone;
      }
    },
  },
  computed:{
    isPhoneValidation() {
      return this.results_tel.isValid;
    }
  }
};
</script>

<style>
.custom-badge {
  background-color: rgba(71, 133, 255, 0.1);
  color: #4785ff;
}
.country-selector__toggle {
  padding-top: 5px !important;
}

.country-selector__input,
.input-tel__input {
  padding-top: 25px !important;
  padding-left: 40px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  letter-spacing: 0.01em !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
  font-family: "Montserrat", sans-serif !important;
  font-variant-ligatures: normal !important;
  font-variant-caps: normal !important;
  font-variant-numeric: normal !important;
  font-variant-east-asian: normal !important;
}

.country-selector__label,
.input-tel__label {
  font-size: 11px !important;
  font-weight: normal !important;
  color: #333333 !important;
  line-height: 1.333rem;
  font-family: "Montserrat", sans-serif !important;
}
</style>
