import Rest from '@/services/Rest';

/**
 * @typedef {AccountStatementsService}
 */
export default class AccountStatementsService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/account-statement'
}
