<template>
  <div class="container">
    <BaseHeader :title="$t('views.seller.leads.text_1112')"> </BaseHeader>

    <div class="container-table">
      <!-- Filtros -->
      <div class="header-table">
        <div class="flex-sale pr-3">
          <div class="d-flex align-items-center">
            <!-- Filter -->
            <b-tooltip
              target="filtro-1"
              :title="$t('views.seller.leads.text_1617')"
              placement="topright"
            />
            <b-button
              id="filtro-1"
              class="btn-table"
              :title="$t('views.seller.leads.text_1618')"
              @click="openFilters"
            >
              <img v-if="!isHeaven" src="@/assets/img/icons/filtro.svg" />
              <img v-else src="@/assets/img/icons-heaven/filtro.svg" />
            </b-button>
            <!-- Download -->
            <b-tooltip
              target="exportar-1"
              :title="$t('views.seller.leads.text_1619')"
              placement="topright"
            />
            <b-button
              @click="exportData"
              class="btn-table"
              :title="$t('views.seller.leads.text_1619')"
              id="exportar-1"
            >
              <img src="@/assets/img/icons/download.svg" />
            </b-button>
          </div>

          <div class="d-flex align-items-center flex-acoes">
            <!-- Date Range Filter -->
            <div class="datas">
              <date-range-picker
                ref="picker"
                opens="right"
                :locale-data="localeDate"
                :showWeekNumbers="false"
                :showDropdowns="true"
                :autoApply="false"
                :ranges="dateRanges"
                v-model="dateRange"
                :class="{'heaven': isHeaven}"
                @update="fetchLeads"
              >
                <template
                  v-if="dateRange.startDate && dateRange.endDate"
                  v-slot:input="picker"
                  style="min-width: 350px"
                >
                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                </template>
                <template
                  v-else
                  v-slot:input
                  class="btn-table"
                  :title="$t('views.seller.leads.text_1620')"
                >
                  {{ $t("views.seller.leads.text_1621") }}
                </template>
              </date-range-picker>
            </div>
            <!-- Search -->
            <div class="pl-3">
              <b-form-input
                type="text"
                :placeholder="$t('views.seller.leads.text_1622')"
                v-on:input="debounce"
                v-model="search"
                style="width: 300px; max-height: 60px"
                :class="{'heaven': isHeaven}"
              />
            </div>
          </div>
        </div>

        <b-col cols="12" md="3" sm="12" class="pr-3 pt-2 container-pesquisa">
          <b-form-select
            :options="[
              { value: null, text: $t('views.seller.leads.text_1623') },
              { value: 'paid', text: $t('views.seller.leads.text_1624') },
              { value: 'abandoned', text: $t('views.seller.leads.text_1625') },
              { value: 'waiting_payment', text: $t('views.seller.leads.text_1626') },
              { value: 'canceled', text: $t('views.seller.leads.text_1627') },
              { value: 'trialing', text: $t('views.seller.leads.text_1628') },
              { value: 'in_negociation', text: $t('views.seller.leads.text_1629') },
              { value: 'capture_form_filled', text: $t('views.seller.leads.text_1400') },
            ]"
            v-on:input="debounce"
            size="sm"
            v-model="status"
            :class="{'heaven': isHeaven}"
          >
          </b-form-select>
        </b-col>

        <b-col cols="12" md="3" sm="12" class="pt-2 container-pesquisa">
          <b-form-select
            :text="$t('views.seller.leads.text_1630')"
            :options="[
              { value: null, text: $t('views.seller.leads.text_1630') },
              { value: 'opened', text: $t('views.seller.leads.text_1631') },
              { value: 'in_negociation', text: $t('views.seller.leads.text_1629') },
              { value: 'objection', text: $t('views.seller.leads.text_1632') },
              { value: 'lost_trading', text: $t('views.seller.leads.text_1633') },
              { value: 'win', text: $t('views.seller.leads.text_1634') },
            ]"
            v-on:input="debounce"
            size="sm"
            v-model="negociation_status"
            :class="{'heaven': isHeaven}"
          >
          </b-form-select>
        </b-col>
      </div>

      <!-- HEADER -->
      <b-row class="Table-header mb-1">
        <b-col cols="1" class="d-none d-md-block"> #ID </b-col>

        <b-col cols="5" md="2" class="d-md-block mr-3">
          {{ $t("views.seller.leads.text_1635") }}
        </b-col>

        <b-col cols="4" md="2" class="d-md-block">
          {{ $t("views.seller.leads.text_1636") }}
        </b-col>

        <b-col cols="1" class="d-none d-md-block mr-1">
          {{ $t("views.seller.leads.text_1637") }}</b-col
        >

        <b-col cols="1" class="d-none d-md-block mr-4">
          {{ $t("views.seller.leads.text_1638") }}</b-col
        >

        <b-col cols="1" class="d-none d-md-block mr-5">
          {{ $t("views.seller.leads.text_1639") }}
        </b-col>

        <b-col cols="2" class="d-none d-md-block">
          {{ $t("views.seller.leads.text_1640") }}</b-col
        >

        <b-col cols="2" md="1" class="">
          {{ $t("views.seller.leads.text_1641") }}
        </b-col>
      </b-row>
      <!-- END HEADER -->

      <!-- Body -->
      <template v-if="!loading && leads.length > 0">
        <b-row class="Table-body" v-for="(item, index) in leads" :key="item.id">
          <!-- ID -->
          <b-col
            cols="1"
            class="d-none d-md-block"
            v-b-tooltip="{
              title: getStatus(item.status).text,
              placement: 'top',
            }"
          >
            <div style="display: flex; align-items: center">
              <div :class="getStatus(item.status).class"></div>
              <span>
                {{ item.id }}
              </span>
            </div>
          </b-col>

          <!-- Cliente -->
          <b-col class="d-md-block dados-cliente mr-3" cols="5" md="2">
            <div>
              {{ item.name }}
            </div>

            <div
              :id="item.email"
              v-b-tooltip="{
                title: item.email,
                placement: 'top',
              }"
            >
              {{
                item.email && item.email.length > maxLength
                  ? item.email.substr(0, maxLength) + "..."
                  : item.email
              }}
            </div>
            <WhatsTel :tel="item.cellphone" :index="index" />
            <div v-if="item.country">
              <div v-b-tooltip="{
                title: 'Pais de origem',
                placement: 'top',
              }">{{  item.country.name && item.country.name.length > maxLength
                  ? item.country.name.substr(0, maxLength) + "..."
                  : item.country.name }}</div>
            </div>
          </b-col>

          <!-- Produtos -->
          <b-col cols="4" md="2" class="d-md-block dados-cliente">
            <div
              v-b-tooltip="{
                title: item.product.name,
                placement: 'top',
              }"
            >
              {{
                item.product.name && item.product.name.length > maxLength
                  ? item.product.name.substr(0, maxLength) + "..."
                  : item.product.name
              }}
            </div>

            <div :id="item.product.type">
              {{
                item.product.type == "TRANSACTION"
                  ? $t("views.seller.leads.text_1113")
                  : $t("views.seller.leads.text_1114")
              }}
            </div>

            <div :id="amountLead(item)" v-if="amountLead(item) && amountLead(item) !== '-'">
              R$ {{ amountLead(item) }}
            </div>
          </b-col>

          <!-- Detalhes da Venda -->

          <b-col cols="1" class="d-none d-md-block dados-cliente mr-1">
            <div v-if="item.sale && item.sale.method">
              <span class="flex">
                <font-awesome-icon
                  v-if="item.sale.method != 'TWO_CREDIT_CARDS'"
                  :icon="getIconMethod(item.sale.method)"
                  class="icon-gray"
                  :id="`method-icon-${item.sale.id}`"
                />
                <b-tooltip
                  :target="`method-icon-${item.sale.id}`"
                  :title="getMethod(item.sale.method)"
                  placement="left"
                />
                <font-awesome-icon
                  v-if="item.sale.method == 'TWO_CREDIT_CARDS'"
                  icon="credit-card"
                  class="icon-gray"
                  :id="`method-icon-${item.sale.id}`"
                />
                <font-awesome-icon
                  v-if="item.sale.method == 'TWO_CREDIT_CARDS'"
                  icon="credit-card"
                  class="icon-gray TWO_CREDIT_CARDS"
                />

                <font-awesome-icon
                  v-if="item.sale.bump_id"
                  icon="donate"
                  class="icon-gray ml-2"
                  :id="`bump-sale-${item.sale.id}`"
                />

                <b-tooltip
                  v-if="item.sale.bump_id"
                  :target="`bump-sale-${item.sale.id}`"
                  title="ORDER BUMP"
                  placement="left"
                />

                <font-awesome-icon
                  v-if="item.sale.coupon_id"
                  icon="receipt"
                  class="icon-gray ml-2"
                  :id="`cupom-sale-${item.sale.id}`"
                />
                <b-tooltip
                  v-if="item.sale.coupon_id"
                  :target="`cupom-sale-${item.sale.id}`"
                  :title="$t('views.seller.leads.text_1642')"
                  placement="left"
                />
              </span>
            </div>
            <div v-else>-</div>

            <div v-if="item.sale && item.sale.installments">
              {{ $t("views.seller.leads.text_1115") }}
              {{ item.sale.installments }}x
            </div>
            <div v-else>-</div>

            <div v-if="item.offer || item.sale && item.sale.amount">
              R$ {{ amountLead(item) }}
            </div>
            <div v-else>-</div>
          </b-col>

          <!-- Afiliado -->
          <b-col class="d-none d-md-block mr-4" cols="1">
            <span v-if="item.affiliate && item.affiliate.name">{{
              item.affiliate.name
            }}</span>
            <span>-</span>
          </b-col>

          <!-- Visto por Último -->

          <b-col
            class="d-none d-md-block mr-5"
            v-b-tooltip="{
              title: !!item.last_seen
                ? moment(item.last_seen).format('DD/MM/YYYY HH:mm')
                : '',
              placement: 'top',
            }"
            cols="1"
          >
            {{
              !!item.last_seen
                ? moment(item.last_seen).locale("pt-br").fromNow()
                : "-"
            }}
          </b-col>

          <!-- Status da negociação -->
          <b-col cols="2" class="pr-5 d-none d-md-block">
            <GSelect
              :uid="`${item.id}`"
              :text="$t('views.seller.leads.text_1119')"
              @change="updateLead($event, item)"
              v-model="item.negociation_status"
              v-if="permissionIsMandatory && leadEdit"
              :options="leadStatusOptions"
            />
            <span v-if="permissionIsMandatory && !leadEdit"> {{ leadStatusOptions.filter(l => l.value === item.negociation_status)[0].text }} </span>
          </b-col>

          <!-- Ações -->
          <b-col cols="2" md="1">
            <div style="display: flex">
              <b-tooltip
                :target="`link-checkout-${item.id}`"
                :title="$t('views.seller.leads.text_1116')"
                placement="top"
              />
              <div
                :id="`link-checkout-${item.id}`"
                @click="copyAddress(`${baseURL}/${item.product.id}`)"
              >
                <img
                  class="pointer"
                  width="16"
                  style="opacity: 0.7; margin-right: 24px"
                  src="@/assets/img/icons/copy.svg"
                />
              </div>
              <div @click="openPanel(item)">
                <img class="pointer" src="@/assets/img/icons/eye.svg" />
              </div>
            </div>
          </b-col>
        </b-row>
      </template>

      <!-- Paginate -->
      <b-row>
        <b-col>
          <Paginate
            v-if="leads.length > 0 && !initExport"
            :totalPages="pagination.totalPages"
            :activePage="pagination.currentPage"
            @to-page="toPage"
            @per-page="perPage"
          />
        </b-col>
      </b-row>

      <!-- Loading -->
      <div
        v-if="(loading && !leads.length) || (loading && initExport)"
        class="Table-body py-4 d-flex justify-content-center align-items-center"
      >
        <b-spinner variant="dark" label="Spinning"></b-spinner>
      </div>

      <b-row
        v-if="!loading && !leads.length"
        class="Table-body justify-content-center"
      >
        <p class="nao-encontrado">{{ $t("views.seller.leads.text_1117") }}</p>
      </b-row>

      <!-- END BODY -->

      <!-- modals -->
      <FiltersModal @filter="filter" />
      <LeadPreview
        :updateQueryParams="setQueryParams"
        @exportDataLead="exportData"
        source="leadPreview"
        ref="leadPreview"
      />
    </div>
    <CallEvaluation />
  </div>
</template>
<script>
import LeadService from "@/services/resources/LeadService";
import Paginate from "@/components/shared/Paginate";
import FiltersModal from "@/components/Seller/Leads/modals/filters";
import DateRangePicker from "vue2-daterange-picker";
import Money from "@/mixins/money";
import moment from "moment-timezone";
import LeadPreview from "@/components/shared/SalePreview";
import GSelect from "@/components/shared/GSelect";
import WhatsTel from "@/components/shared/WhatsTel";
import _ from "lodash";
import { iconMethod } from "@/helpers.js";
import { textMethod } from "@/helpers.js";
import Cookies from "js-cookie";
import gtagSend from '@/utils/gtagSend';

const service = LeadService.build();

export default {
  name: "ListaDeLeads",
  components: {
    Paginate,
    FiltersModal,
    DateRangePicker,
    LeadPreview,
    GSelect,
    WhatsTel,
  },
  data() {
    return {
      leadStatusOptions: [
        { value: 'opened', text: this.$t('views.seller.leads.text_1631') },
        {
          value: 'in_negociation',
          text: this.$t('views.seller.leads.text_1629'),
        },
        {
          value: 'objection',
          text: this.$t('views.seller.leads.text_1632'),
        },
        {
          value: 'lost_trading',
          text: this.$t('views.seller.leads.text_1633'),
        },
        { value: 'win', text: this.$t('views.seller.leads.text_1634') },
      ],
      moment: moment,
      initExport: false,
      leads: [],
      sale_id: -1,
      search: null,
      lead_status: null,
      loading: true,
      total: 0,
      itens: 0,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
      },
      filters: {
        status: null,
        negociation_status: null,
      },
      type_date: "created_at",
      types_list: [
        { value: "created_at", text: "Data de criação" },
        { value: "paid_at", text: "Data de pagamento" },
      ],
      localeDate: {
        direction: "ltr",
        format: "dd/mm/yyyy",
        separator: " - ",
        applyLabel: this.$t('locale_date.apply_label'),
        cancelLabel: this.$t('locale_date.cancel_label'),
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: this.$t('locale_date.days_of_week'),
        monthNames: this.$t('locale_date.month_names'),
        firstDay: 0,
      },
      dateRange: {
        startDate: moment(),
        endDate: moment(),
      },
      client: {
        width: 0,
      },
      status: null,
      negociation_status: null,
      toogleTel: [],
      productRead: false,
      leadRead: false,
      leadEdit: false,
      permissionIsMandatory: false,
    };
  },
  mixins: [Money],
  computed: {
    baseURL() {
      return this.isHeaven ? process.env.VUE_SALES_HEAVEN_PAGE : process.env.VUE_SALES_FAST_PAGE;
    },
    active() {
      return this.$route.name;
    },
    isSeller() {
      return this.$store.getters.isSeller;
    },
    userID() {
      return this.$store.getters.getProfile.id;
    },
    maxLength() {
      return this.$store.getters["sidebarState"] ? 20 : 27;
    },
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let lastWeek = new Date();
      lastWeek.setDate(today.getDate() - 7);
      lastWeek.setHours(0, 0, 0, 0);

      return {
        [this.$t("locale_date.today")]: [today, today],
        [this.$t("locale_date.yesterday")]: [yesterday, yesterday],
        [this.$t("locale_date.last_week")]: [lastWeek, today],
        [this.$t("locale_date.current_month")]: [
          new Date(today.getFullYear(), today.getMonth(), 1),
          new Date(today.getFullYear(), today.getMonth(), 30),
        ],
        [this.$t("locale_date.current_year")]: [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31),
        ],
        [this.$t("locale_date.last_month")]: [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        [this.$t("locale_date.every_period")]: [new Date(2020, 0, 1), new Date(today)],
      };
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    getPermissions() {
      if (this.$store.getters.setPanel) {
        this.permissionIsMandatory = true;
        var dados = Cookies.get("setPanelPermission");
        if (dados) {
          dados = JSON.parse(dados);
          if (dados.find((x) => x.name === "productRead")) {
            this.productRead = true;
          }
          if (dados.find((x) => x.name === "LeadRead")) {
            this.leadRead = true;
          }
          if (dados.find((x) => x.name === "LeadEdit")) {
            this.leadEdit = true;
          }
        }
      } else {
        this.permissionIsMandatory = false;
      }
    },
    amountLead(itemList) {
      let amount = 0;
      if (!!itemList.offer) {
        amount = this.formatMoney(itemList.offer.amount)
      } else if (itemList.sale) {
        amount = this.formatMoney(itemList.sale.amount)
      } else {
        amount = '-'
      }
      return amount;
    },
    getIconMethod(method) {
      return iconMethod(method);
    },
    getMethod(method) {
      return this.textMethod(method);
    },
    changeToggle(index) {
      console.log("aqui", index, this.toogleTel[index]);
      if (this.toogleTel[index] == true) {
        this.toogleTel[index] = false;
      } else {
        this.toogleTel[index] = true;
      }
    },
    maskTel(v) {
      let r = v.replace(/\D/g, "");
      r = r.replace(/^0/, "");

      if (r.length > 11) {
        r = r.replace(/^(\d\d)(\d{2})(\d{4,5})(\d{4}).*/, "$1 ($2) $3-$4");
      } else if (r.length > 7) {
        r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, "($1) $2-$3");
      } else if (r.length > 2) {
        r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
      } else if (v.trim() !== "") {
        r = r.replace(/^(\d*)/, "($1");
      }
      return r;
    },
    getDDD(number) {
      return parseInt(number.match(/(?:\d{2})(\d{2})/)[1]);
    },
    removeNineDigit(number) {
      if (!number) return number;
      const replaceRegex = new RegExp("(\\d{4})(9)(\\d*)");
      return number.replace(replaceRegex, "$1$3");
    },
    addNineDigit(number) {
      const replaceRegex = new RegExp("(\\d{4})(\\d*)");
      return number.replace(replaceRegex, "$19$2");
    },
    isBrazilian(number) {
      const brasilianRegex = new RegExp("^55");
      return brasilianRegex.test(number);
    },
    getRawNumber(number) {
      const numRegex = new RegExp("(\\D)", "g");
      return `${number}`.replace(numRegex, "");
    },
    getSecondLink(number) {
      try {
        let parsedNum = this.parseNum(number);
        if (!this.isBrazilian(parsedNum)) {
          return false;
        } else {
          if (this.getDDD(parsedNum) > 30) {
            return `https://api.whatsapp.com/send?phone=+${parsedNum}`;
          } else {
            return false;
          }
        }
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    parseNum(number) {
      try {
        let rawNumber = this.getRawNumber(number);
        if (this.isBrazilian(rawNumber)) {
          if (this.getDDD(rawNumber) > 30) {
            if (rawNumber.length > 12) {
              rawNumber = this.removeNineDigit(rawNumber);
            }
          } else {
            if (rawNumber.length < 13) {
              rawNumber = this.addNineDigit(rawNumber);
            }
          }
        }
        return rawNumber;
      } catch (error) {
        console.error(error);
        return number;
      }
    },
    formatTel(tel) {
      let parsedNum = this.parseNum(tel);
      if (parsedNum) {
        return `https://api.whatsapp.com/send?phone=${parsedNum}`;
      }
    },
    iconMethod(method) {
      switch (method) {
        case "CREDIT_CARD": return "credit-card"
        case "BOLETO": return "barcode"
        case "PIX": return "qrcode"
        case "PAYPAL": return ["fab", "paypal"]
        default: return "coins"
      }
    },
    getStatus(status) {
      if (status) {
        switch (status.toLowerCase()) {
          case "capture_form_filled":
            return { text: this.$t("views.seller.leads.text_1400"), class: "status status--opened" }
          case "opened":
            return { text: this.$t("views.seller.leads.text_1631"), class: "status status--opened" }
          case "in_negociation":
            return { text: this.$t("views.seller.leads.text_1629"), class: "status status--in_negociation" }
          case "objection":
            return { text: this.$t("views.seller.leads.text_1632"), class: "status status--objection" }
          case "lost_trading":
            return { text: this.$t("views.seller.leads.text_1633"), class: "status status--lost_trading" }
          case "win":
            return { text: this.$t("views.seller.leads.text_1634"), class: "status status--win" }
          case "":
            return { text: "list", class: "is-red" }
          case "waiting_payment":
           return { text: this.$t("views.seller.leads.text_1626"), class: "status status--opened" }
          case "paid":
            return { text: this.$t("views.seller.leads.text_1624"), class: "status status--in_negociation" }
          case "abandoned":
            return { text: this.$t("views.seller.leads.text_1625"), class: "status status--lost_trading" }
          case "canceled":
            return { text: this.$t("views.seller.leads.text_1627"), class: "status status--objection" }
          case "trialing":
            return { text: this.$t("views.seller.leads.text_1628"), class: "status status--win" }
          case "pending_approbation":
            return { text: this.$t("views.seller.leads.text_1120"), class: "is-light-blue" }
          case "pending_refund":
          case "refund_pending":
            return { text: "Reembolso pendente", class: "is-warning" }
          case "refused":
            return { text: "Rejeitado", class: "is-red" }
          case "chargedback":
            return { text: "Estorno", class: "is-warning" }
          case "delayed":
            return { text: "Em processamento", class: "is-warning" }
          default:
            return { text: status, class: "status" }
        }
      } else {
        return { text: this.$t("views.seller.leads.text_1121"), class: "status" }
      }
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
    changedType() {
      // chamando dessa forma para nao
      // passar o event como parametro
      this.fetchLeads();
    },
    debounce: _.debounce(function () {
      let filters = {
        per_page: this.pagination.perPage,
        page: this.pagination.currentPage,
        ...this.filters,
      };

      if (this.negociation_status) {
        filters.negociation_status = this.negociation_status;
      }

      if (this.status) {
        filters.status = this.status;
      }

      let search = null;

      if (this.search && this.search !== "") {
        search = this.search;
      }

      this.fetchLeads(search, false, filters);
    }, 500),
    getCoSellerAmount(item) {
      if (item.product.co_sellers) {
        for (let i = 0; i < item.product.co_sellers.length; i++) {
          if (item.product.co_sellers[i].user_id == this.userID) {
            const percentage = item.product.co_sellers[i].percentage;
            return ((item.amount - item.fee) * percentage) / 100;
          }
        }
      }

      return 0;
    },
    openPanel(item) {
      this.sale_id = item.id;
      this.$router.push({ query: { l_id: item.id } }).catch(() => {});

      setTimeout(() => {
        this.$refs.leadPreview.openPanel(item.id, "lead");
        this.loading = false;
      });
    },
    openFilters() {
      this.$bvModal.show("leads-filters");
    },
    exportData() {
      this.fetchLeads(null, true);
    },
    toPage(page) {
      this.pagination.currentPage = page;
      this.debounce();
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;

      this.debounce();
    },
    filter(filters) {
      this.filters = filters;
      this.pagination.currentPage = 1;
      this.fetchLeads();
    },
    fetchLeads(search = null, exportData = false, dados_local = null) {
      this.$router.replace({ query: { start_date: moment(this.dateRange.startDate).format("MM-DD-YYYY"), end_date: moment(this.dateRange.endDate).format("MM-DD-YYYY"), ...this.filters } }).catch(() => {});
      if (search !== 'not-loading') this.loading = true;
      // this.pagination.totalPages = 1;

      let data = {};
      if (dados_local) {
        data = dados_local;
      } else {
        data = {
          page: this.pagination.currentPage,
          per_page: this.pagination.perPage,
        };
      }

      if (!exportData) {
        // reset
        if (search !== 'not-loading'){
         this.pagination.totalPages = 1;;
         this.leads = [];
         this.total = 0;
         this.itens = 0;
        }
      } else {
        this.initExport = true;
        this.$bvToast.toast(this.$t("views.seller.leads.text_1122"), {
          variant: "info",
          title: this.$t("views.seller.leads.text_1643"),
          autoHideDelay: 8000,
          appendToast: true,
        });
      }

      if (search != null && typeof search == "string" && search.length !== "" && search !== "not-loading") {
        data.search = search;
      }

      if (this.dateRange.startDate != null && this.dateRange.endDate != null) {
        data.start_date = moment(this.dateRange.startDate)
          .tz("America/Sao_Paulo")
          .format("YYYY-MM-DDT00:00:00-03:00");
        data.end_date = moment(this.dateRange.endDate)
          .tz("America/Sao_Paulo")
          .format("YYYY-MM-DDT23:59:59-03:00");
      }

      Object.keys(this.filters).forEach(
        (key) =>
          (this.filters[key] == null || this.filters[key].length === 0) &&
          delete this.filters[key]
      );
      data = Object.assign(data, this.filters);

      if (exportData) data.export = true;

      if (data.start_date.includes("Invalid date") || data.end_date.includes("Invalid date")) return;
      service
        .search(data)
        .then(response => {
          if (exportData) {
            this.download(response)
          } else {
            this.leads = response.data
            this.pagination.totalPages = response.last_page
            this.pagination.perPage = parseInt(response.per_page)
            this.total = response.total
            this.itens = response.total
          }
        })
        .catch(err => console.error(err))
        .finally(() => { this.loading = false })
    },
    download(data) {
      var file =
        "Cod;Produto;Cpf;Nome;E-mail;Endereco;Telefone;Visto Por Ultimo;Status da Negociacao\n";
      var filename = "Greenn_" + moment().format("DD-MM-YYYY") + ".csv";

      for (let i = 0; i < data.length; i++) {
        let endereco = data[i].street
          ? `${data[i].street} ${data[i].number} ${data[i].neighborhood} ${data[i].city} ${data[i].zipcode} ${data[i].complement}`
          : "Sem endereço";

        file +=
          data[i].id +
          ";" +
          (data[i].proposal ? data[i].proposal.name : data[i].product.name) +
          ";" +
          data[i].cpf +
          ";" +
          data[i].name +
          ";" +
          data[i].email +
          ";" +
          endereco +
          ";" +
          data[i].cellphone +
          ";" +
          moment(data[i].last_seen).locale("pt-br").fromNow() +
          ";" +
          this.getStatus(data[i].negociation_status).text +
          "\n";
      }

      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(file)
      );
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);

      this.$bvToast.toast(this.$t("views.seller.leads.text_1644"), {
        variant: "success",
        title: this.$t("views.seller.leads.text_1643"),
        autoHideDelay: 5000,
        appendToast: true,
      });
      this.initExport = true;
    },
    getMetas(metas) {
      var result = [];
      if (metas.length) {
        metas.forEach((element) => {
          result.push(element.meta_key + "=" + element.meta_value);
        });
      }

      return result.join("&");
    },
    decodeDateFilter(date) {
      switch (date) {
        // hoje
        case "hoje": {
          return moment();
        }
        // ontem
        case "ontem": {
          return moment().subtract(1, "days");
        }
        // uma semana atras
        case "uma semana": {
          return moment().subtract(7, "days");
        }
        // primeiro dia do mes atual
        case "primeiro dia mes atual": {
          return moment().startOf("month");
        }
        // ultimo dia do mes atual
        case "ultimo dia mes atual": {
          return moment().endOf("month");
        }
        // primeiro dia do ANO atual
        case "primeiro dia ano": {
          return moment().startOf("year");
        }
        // ultimo dia do ANO atual
        case "ultimo dia ano": {
          return moment().endOf("year");
        }

        // primeiro dia do mes passado
        case "primeiro dia mes passado": {
          return moment().startOf("month").subtract(1, "month");
        }
        // ultimo dia do mes passado
        case "ultimo dia mes passado":
          return moment().endOf("month").subtract(1, "month").add(1, "days");
        default: {
          return date;
        }
      }
    },
    formatDateFilter(date) {
      switch (date) {
        // hoje
        case moment().format("YYYY-MM-DD"): {
          return "hoje";
        }
        // ontem
        case moment().subtract(1, "days").format("YYYY-MM-DD"): {
          return "ontem";
        }
        // uma semana atras
        case moment().subtract(7, "days").format("YYYY-MM-DD"): {
          return "uma semana";
        }
        // primeiro dia do mes atual
        case moment().startOf("month").format("YYYY-MM-DD"): {
          return "primeiro dia mes atual";
        }
        // ultimo dia do mes atual
        case moment().endOf("month").format("YYYY-MM-DD"): {
          return "ultimo dia mes atual";
        }
        // primeiro dia do ANO atual
        case moment().startOf("year").format("YYYY-MM-DD"): {
          return "primeiro dia ano";
        }
        // ultimo dia do ANO atual
        case moment().endOf("year").format("YYYY-MM-DD"): {
          return "ultimo dia ano";
        }

        // primeiro dia do mes passado
        case moment()
          .startOf("month")
          .subtract(1, "month")
          .format("YYYY-MM-DD"): {
          return "primeiro dia mes passado";
        }
        // ultimo dia do mes passado
        case moment().endOf("month").subtract(1, "month").format("YYYY-MM-DD"):
        case moment()
          .endOf("month")
          .subtract(1, "month")
          .add(1, "days")
          .format("YYYY-MM-DD"): {
          return "ultimo dia mes passado";
        }
        default: {
          return date;
        }
      }
    },
    copyAddress(href) {
      const el = document.createElement("textarea");
      el.value = href;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$bvToast.toast(this.$t("views.seller.leads.text_1123"), {
        variant: "success",
        title: this.$t("views.seller.leads.text_1645"),
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    updateLead(negociation_status, lead) {
      const leads = this.leads;
      let lead_to_update = lead;
      let new_leads = [];
      leads.map((l) => {
        let new_lead = l;
        if (l.id === lead.id) {
          new_lead.negociation_status = negociation_status;
          lead_to_update.negociation_status = negociation_status;
        }
        new_leads.push(new_lead);
      });

      this.leads = new_leads;

      service
        .update(lead_to_update)
        .then(() => {
          this.$bvToast.toast(this.$t("views.seller.leads.text_1124"), {
            variant: "success",
            title: this.$t("views.seller.leads.text_1646"),
            autoHideDelay: 5000,
            appendToast: true
          })
        })
        .catch(err => console.error(err))
        .finally(() => { this.loading = false })
    },
    setQueryParams() {
      this.dateRange.startDate = this.$route.query.start_date || this.dateRange.startDate || moment();
      this.dateRange.endDate = this.$route.query.end_date || this.dateRange.endDate || moment();
      this.filters.products = this.$route.query.products || this.filters.products || "";
      this.fetchLeads('not-loading');
    },
    textMethod(method) {
      if(method) {
        switch (method.toUpperCase()) {
          case "CREDIT_CARD": return this.$t('views.seller.edit_product.text_1112')
          case "CREDIT-CARD": return this.$t('views.seller.edit_product.text_1112')
          case "BANKTRANSFER": return this.$t('views.seller.edit_product.text_1113')
          case "CREDITCARD": return this.$t('views.seller.edit_product.text_1112')
          case "DEBITCARD": return this.$t('views.seller.edit_product.text_1114')
          case "debitcard": return this.$t('views.seller.edit_product.text_1114')
          case "TWO_CREDIT_CARDS": return this.$t('views.seller.edit_product.text_1115')
          case "DEBITCARD": return "CARTÃO DE DÉBITO"
          case "EFT": return "EFT"
          case "SPEI": return "SPEI"
          case "SENCILLITO": return "SENCILLITO"
          case "OXXO": return "OXXO"
          default: return method
        }
      } else return method;
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize)
    this.handleResize()
    this.getPermissions();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize)
  },
  mounted() {
    
    gtagSend('lista_leads_acessada',{
      ID_cliente: this.userID,
    })
    this.loading = true
    // abrindo venda unica
    if (typeof this.$route.query.l_id != "undefined") {
      this.sale_id = this.$route.query.l_id
      this.fetchLeads()
      setTimeout(() => {
        this.$refs.leadPreview.openPanel(this.sale_id, "lead")
        this.loading = false
      }, 1000)
    } else {
      this.setQueryParams()
    }
  }
}
</script>

<style lang="scss" scoped>
.metas {
  list-style: none;
  font-size: 11px;

  li {
    word-break: break-all;
  }
}
.btn-table {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: rgba(237, 237, 240, 0.5);
  outline: none;
}
.btn-table:active {
  background: rgba(237, 237, 240, 0.5);
  border: none;
}
.btn-table img {
  filter: invert(50%);
}
.btn-table:first-child {
  background: rgba(0, 148, 136, 0.1);
}
.btn-table:first-child:active {
  background: rgba(0, 148, 136, 0.1);
  border: none;
}
.btn-table:first-child img {
  filter: invert(0);
}
.btn-table:nth-child(2) {
  margin-left: 15px;
  margin-right: 20px;
}
.header-table {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  padding-top: 15px;
}
.flex-sale {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.reset-data {
  font-size: 14px;
  color: #81858e;
  font-weight: normal;
  margin-left: 15px;
}
.input-busca {
  width: 350px;
  height: 60px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 5px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
}
.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}

.search {
  position: absolute;
  left: 52px;
  top: 22px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}
@media screen and (max-width: 1300px) {
  .search {
    left: 30px;
  }
}
.container-pesquisa {
  text-align: right;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .header-table {
    display: block;
    padding: 0;
  }
  .flex-sale {
    display: block;
  }
  .flex-sale div {
    margin-left: 0;
  }
  .flex-acoes {
    margin: 15px 0;
  }
  .container-pesquisa {
    text-align: left;
  }
  .search {
    left: 20px;
  }
  .input-busca {
    width: 100%;
  }
}
.dados-cliente div {
  margin-bottom: 3px;
}
.dados-cliente div:nth-child(2) {
  font-weight: normal;
  font-size: 13px;
}

.valor-venda {
  margin-left: 10px;
}

#id_venda {
  position: relative;
}
#id_venda::before {
  content: "";
  width: 15px;
  height: 15px;
  background: transparent;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: -25px;
  top: 0;
}
.TWO_CREDIT_CARDS {
  position: absolute;
  left: 5px;
  top: -5px;
}

// tipo data
#type {
  margin-bottom: 0 !important;
  margin-left: 10px;
}
.Table-body {
  padding: 15px;
}
.Table-body::before {
  left: 0;
}

.fonte {
  font-size: 14px;
  color: #333;
  font-weight: 600;
}
.btn-status {
  height: 40px;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 500;
}
.pointer {
  cursor: pointer;
}
.status {
  width: 6px;
  height: 6px;
  background: #333;
  border-radius: 50%;
  margin-right: 6px;
  transition: all 0.3s ease;
}
.status--opened {
  background: #2133d2;
}
.status--objection {
  background: #FF9E44;
}
.status--in_negociation {
  background: #2ed7ee;
}
.status--lost_trading {
  background: #ff0c37;
}
.status--win {
  background: #009488;
}
</style>
