import Rest from '@/services/Rest';

/**
 * @typedef {RefundBumpService}
 */
export default class RefundBumpService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/sale-orderbumps'
}
