import Rest from '@/services/Rest';

/**
 * @typedef {ProductAfilliate}
 */
export default class ProductAfilliate extends Rest {
  /**
   * @type {String}
   */
  static resource = '/product-affiliate'
}
